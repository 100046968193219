<template>
  <div class="agent-account-audit-page-withdrawal">
    <el-form
      :inline="true"
      :model="form"
      class="demo-form-inline"
      style="width: 100%"
    >
      <el-form-item label="代理商编号">
        <el-input v-model="form.code" placeholder="请输入代理商编号"></el-input>
      </el-form-item>
      <el-form-item label="代理商姓名">
        <el-input v-model="form.name" placeholder="请输入代理商姓名"></el-input>
      </el-form-item>
      <el-form-item label="代理商电话">
        <el-input
          v-model="form.phone"
          placeholder="请输入代理商电话"
        ></el-input>
      </el-form-item>
      <!--
      <el-form-item label="商户名称">
        <el-input v-model="form.shopName" placeholder="请输入商户名称"></el-input>
      </el-form-item>
      <el-form-item label="商户手机号">
        <el-input v-model="form.shopPhone" placeholder="请输入商户手机号"></el-input>
      </el-form-item>
      -->
      <el-form-item label="提现时间" class="select-form-item">
        <el-date-picker
          v-model="addTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核状态" class="select-form-item">
        <el-select v-model="form.startStatus" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="screen('search')">筛选</el-button>
        <el-button type="info" @click="clear">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
      <el-table-column
        prop="agentSid"
        label="代理编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="agentName"
        label="代理姓名"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="agentPhone"
        label="手机号"
        width="180"
      ></el-table-column>
      <el-table-column prop="createTime" label="提交申请时间" width="200">
        <template slot-scope="scope">
          <span>{{
            $formatDate(scope.row.createTime, "yyyy-MM-dd HH:mm")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="提现金额" width="200">
      </el-table-column>
      <el-table-column prop="type" label="到账方式" width="180">
        <template slot-scope="scope">
          <span>{{ typeList[scope.row.type].label }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" width="180px">
        <template slot-scope="scope">
          <span>{{ options[+scope.row.status].label }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isFix" label="修正状态" width="180px">
        <template slot-scope="scope">
          <span>{{
            +scope.row.status === 5 || +scope.row.status === 3
              ? +scope.row.isFix === 1
                ? "未修正"
                : "已修正"
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="详情">
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: red"
            v-if="
              +scope.row.status === 1 ||
              (+scope.row.status === 3 && +scope.row.isFix === 1) ||
              (+scope.row.status === 5 && +scope.row.isFix === 1)
            "
            @click="see(scope.row)"
            >详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      :page-count="totalPage"
      :current-page.sync="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="detailVisible"
      width="950px"
      :destroy-on-close="true"
      :before-close="closeFunc"
    >
      <div class="detailContent" v-if="showStatus && showStatus === 1">
        <div class="detail-title">提现信息详情</div>
        <div class="dialogMiddleCon">
          <div class="mt40 flexbox flex-lr flex-align-center">
            <div class="flex_1">
              代理姓名：{{ dialogData.agentWithdraw.agentName }}
            </div>
            <div class="flex_1">
              代理编号：{{ dialogData.agentWithdraw.agentSid }}
            </div>
            <div class="flex_1">
              代理手机：{{ dialogData.agentAuthBind.phone }}
            </div>
            <div class="flex_1">
              提交时间：{{
                $formatDate(
                  dialogData.agentWithdraw.createTime,
                  "yyyy-MM-dd HH:mm"
                )
              }}
            </div>
          </div>
          <div class="fontAbout mt40 flexbox flex-lr flex-align-stretch">
            <div class="flex_1">
              <div>本次提现金额：{{ dialogData.agentWithdraw.amount }}元</div>
              <div class="mt20" v-if="dialogData.agentWithdraw.accountType == 1">提现账户：余额账户</div>
              <div class="mt20" v-if="dialogData.agentWithdraw.accountType == 2">提现账户：运营账户</div>
            </div>
            <div class="">
              <div style="line-height: 40px">
                到账方式：{{ typeList[dialogData.agentWithdraw.type].label }}
                {{ dialogData.agentWithdraw.bankCardId }}
              </div>
              <div style="line-height: 40px">
                持卡人姓名：{{ dialogData.agentWithdraw.bankName }}
              </div>
              <div style="line-height: 40px">
                银行预留手机号：{{ dialogData.agentWithdraw.agentPhone }}
              </div>
            </div>
          </div>
          <div class="radioDiv">
            <el-radio
              class="radioMargin"
              size="medium"
              v-model="radio"
              label="2"
              >审核成功</el-radio
            >
            <el-radio
              class="radioMargin"
              size="medium"
              v-model="radio"
              label="3"
              >审核失败</el-radio
            >
          </div>
        </div>
        <div class="dialog-footer flexbox flex-lr flex-justify-center mt40">
          <el-button type="primary" @click="clickExamine">确认</el-button>
        </div>
      </div>
      <div class="detailContent" v-if="showStatus && showStatus === 2">
        <div class="detail-title">
          {{ +radio === 2 ? "同意" : "驳回" }}提现申请
        </div>
        <div class="mt40">
          <div>
            您将{{ +radio === 2 ? "同意" : "驳回" }}代理商"{{
              dialogData.agentWithdraw.agentName
            }}"的提现申请，请确认提现信息并添加备注后确认出款。
          </div>
          <div class="fontAbout mt40 flexbox flex-lr flex-align-stretch">
            <div class="flex_1">
              本次提现金额：{{ dialogData.agentWithdraw.amount }}元
            </div>
            <div class="">
              <div style="line-height: 40px">
                到账方式：{{ typeList[dialogData.agentWithdraw.type].label }}
                {{ dialogData.agentWithdraw.bankCardId }}
              </div>
              <div style="line-height: 40px">
               持卡人姓名：{{ dialogData.agentWithdraw.bankName }}
              </div>
              <div style="line-height: 40px">
                银行预留手机号：{{ dialogData.agentWithdraw.agentPhone }}
              </div>
            </div>
          </div>
          <div class="flexbox flex-lr flex-justify-start mt40">
            <div class="remake">备注:</div>
            <el-input
              class="textarea"
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="textarea"
            >
            </el-input>
          </div>
        </div>
        <div class="dialog-footer flexbox flex-lr flex-justify-center mt40">
          <el-button type="primary" @click="sureExamine">{{
            +radio === 2 ? "确认无误，提交出款" : "确认无误，驳回申请"
          }}</el-button>
        </div>
      </div>
      <div class="detailContent" v-if="showStatus && showStatus === 3">
        <div class="detail-title">提现信息详情</div>
        <div class="dialogMiddleCon">
          <div class="mt40 flexbox flex-lr flex-align-center flex-wrap">
            <div class="infoLi">
              代理姓名：{{ dialogData.agentWithdraw.agentName }}
            </div>
            <div class="infoLi">
              代理编号：{{ dialogData.agentWithdraw.agentSid }}
            </div>
            <div class="infoLi">
              代理手机：{{ dialogData.agentAuthBind.phone }}
            </div>
            <div class="infoLi">
              提交时间：{{
                $formatDate(
                  dialogData.agentWithdraw.createTime,
                  "yyyy-MM-dd HH:mm"
                )
              }}
            </div>
            <div class="infoLi">
              审核时间：{{
                $formatDate(
                  dialogData.agentWithdraw.updateTime,
                  "yyyy-MM-dd HH:mm"
                )
              }}
            </div>
          </div>
          <div class="fontAbout mt40 flexbox flex-lr flex-align-stretch">
            <div class="flex_1">
              本次提现金额：{{ dialogData.agentWithdraw.amount }}元
            </div>
            <div class="">
              <div style="line-height: 40px">
                到账方式：{{ typeList[dialogData.agentWithdraw.type].label }}
                {{ dialogData.agentWithdraw.bankCardId }}
              </div>
              <div style="line-height: 40px">
                持卡人姓名：{{ dialogData.agentWithdraw.bankName }}
              </div>
              <div style="line-height: 40px">
                银行预留手机号：{{ dialogData.agentWithdraw.agentPhone }}
              </div>
            </div>
          </div>
          <div class="radioDiv">
            <div>
              此笔提现申请，打款状态为：<span class="statusColor">{{
                options[+dialogData.agentWithdraw.status].label
              }}</span>
            </div>
          </div>
        </div>
        <div class="dialog-footer flexbox flex-lr flex-justify-center mt40">
          <el-button type="primary" @click="correctOrder">修正订单</el-button>
          <el-button type="info" @click="detailVisible = false">返回</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  $sp_queryWidthdrawList,
  $sp_queryWithdrawDetail,
  $sp_verify,
  $sp_withdrawFix,
} from '@/api';

export default {
  name: 'ProxyManageWithdrawal',
  data() {
    return {
      form: {
        code: '',
        name: '',
        phone: '',
        startStatus: '',
      },
      options: [
        { value: '', label: '全部' },
        { value: 1, label: '待审核' },
        { value: 2, label: '已通过' },
        { value: 3, label: '已驳回' },
        { value: 4, label: '已审核-出款中' },
        { value: 5, label: '已审核-出款失败' },
      ],
      typeList: [
        { value: '', label: '全部' },
        { value: 1, label: '支付宝' },
        { value: 2, label: '微信' },
        { value: 3, label: '银行卡' },
      ],
      addTime: '',
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      detailVisible: false,
      dialogData: '',
      showStatus: '',
      radio: '2',
      textarea: '',
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList(param) {
      const params = {
        agentSid: this.form.code,
        agentName: this.form.name,
        agentPhone: this.form.phone,
        status: this.form.startStatus,
        startTime:
          this.addTime && this.addTime.length > 0
            ? this.$formatDateByDate(this.addTime[0], 'yyyy-MM-dd')
            : '',
        endTime:
          this.addTime && this.addTime.length > 0
            ? this.$formatDateByDate(this.addTime[1], 'yyyy-MM-dd')
            : '',
        pageCurrent: param ? 1 : this.currentPage,
        pageSize: 15,
      };
      $sp_queryWidthdrawList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    pageChange() {
      this.getList();
    },
    screen(param) {
      this.getList(param);
    },
    clear() {
      this.form = {
        code: '',
        name: '',
        phone: '',
        startStatus: '',
      };
      this.addTime = '';
      this.getList();
    },
    see(data) {
      $sp_queryWithdrawDetail({ code: data.code }).then((res) => {
        this.dialogData = res;
        this.showStatus = res.agentWithdraw.status === 3 || res.agentWithdraw.status === 5
          ? 3
          : 1;
        this.detailVisible = true;
      });
    },
    clickExamine() {
      this.showStatus = 2;
    },
    sureExamine() {
      const params = {
        code: this.dialogData.agentWithdraw.code,
        status: this.radio,
        remark: this.textarea,
      };
      $sp_verify(params)
        .then(() => {
          this.detailVisible = false;
          this.radio = '2';
          this.textarea = '';
          this.getList();
        })
        .catch(() => {
          this.radio = '2';
          this.textarea = '';
          this.detailVisible = false;
        });
    },
    correctOrder() {
      $sp_withdrawFix({ code: this.dialogData.agentWithdraw.code })
        .then((res) => {
          console.log('FHQ看看返回', res);
          this.detailVisible = false;
          this.getList();
        })
        .catch((error) => {
          this.detailVisible = false;
          console.log('FHQ看看错误', error);
          this.$message.error(error.msg);
        });
    },
    closeFunc() {
      this.radio = '2';
      this.textarea = '';
      this.detailVisible = false;
    },
  },
};
</script>

<style lang="scss">
.agent-account-audit-page-withdrawal .el-dialog .el-dialog__header {
  padding: 0;
  border: none;
}
.agent-account-audit-page-withdrawal .el-dialog__body {
  padding: 30px 50px;
}
</style>
<style scoped lang="scss">
@import "@/style/var.scss";
.agent-account-audit-page-withdrawal {
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
}
.el-form--inline .el-form-item {
  margin-right: 50px;
}
.el-table {
  min-height: 580px;
}
.detail-title {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  text-align: center;
}
.infoLi {
  width: 25%;
  margin-bottom: 10px;
}
.dialog-footer > button {
  width: 260px;
  height: 36px;
}
.radioDiv {
  margin-top: 80px;
}
.fontAbout div {
  font-size: 16px;
  color: #333;
}
.radioMargin {
  margin-right: 80px;
}
.remake {
  font-size: 14px;
  margin-right: 20px;
}
.textarea {
  width: 400px;
}
.statusColor {
  color: $--red-color;
}
</style>
